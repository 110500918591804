<template>
  <v-app id="root">
    <v-app-bar app  dense dark color="primary" class="app-header">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="vermenu"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-md-flex">
<!--        Hola {{ firstName }}{{ nameCompany }}-->
        {{ name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select v-if="companies.length" v-model="selectedCompany" :items="companies" item-value="id" item-text="nombre"
      dense solo prepend-inner-icon="mdi-factory" class="mx-5 v-step-0" light hide-details style="max-width: 300px;">
      </v-select>
      <v-btn v-if="!isPublic()" :disabled="report" @click="getReportGeneral()" small rounded color="success" class="text-normal white--text mx-1 d-none d-md-flex downloadreport">
        <v-icon left>mdi-download</v-icon>
        Imprimir Reporte
      </v-btn>
      <v-btn v-if="isPublic()" small rounded color="secondary" class="text-normal black--text mx-1 d-none d-md-flex" :href="links.calendly" target="_blank">
        Entrevista
      </v-btn>
      <v-btn v-if="!isPublic()" @click="tour()" small rounded color="secondary" class="text-normal black--text mx-1 d-none d-md-flex">
        Ayuda
      </v-btn>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click.stop="drawerRight = !drawerRight" text rounded class="text-normal mx-1 d-none d-md-flex">
            <v-badge :value="no_leidos.length" :content="no_leidos.length" color="red" overlap>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
<!--            {{no_leidos.length}}-->
          </v-btn>
        </template>
      </v-menu>
      <v-toolbar-title class="d-none d-md-flex mx-2">
<!--        {{ name }}-->
      </v-toolbar-title>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="text-normal mx-1 d-none d-sm-flex" v-bind="attrs" v-on="on" text>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list style="width: 200px">
          <v-list-item>
            <v-btn @click.native="profile()" class="text-normal" style="width: 100%">
              <v-list-item-title>Perfil</v-list-item-title>
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn @click.native="logout()" color="secondary" class="text-normal black--text" style="width: 100%">
              <v-list-item-title>Salir</v-list-item-title>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" class="app-sidemenu" temporary width="270">
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title">
            <v-container>
              <v-img :src="logo"/>
            </v-container>
          </v-list-item-title>
          <v-list-item-subtitle color="primary">
            <v-chip class="ma-2 black--text" color="secondary" small>
              v{{version}}
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle color="primary">
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="!isPublic()">
        <v-list-item-content class="text-center">
          <v-list-item-title color="primary">
           <p style="font-weight: bold">Objetivo</p>
            <p>
              {{objective}}
            </p>
          </v-list-item-title>
          <v-list-item-subtitle color="primary">
            <v-select ref="objectiveSelect" class="ma-2 black--text objetiveselected" v-if="objective === null" color="secondary" :items="objectives" v-model="objective" @change="objectiveCheck()" small label="Establecer objetivo">
            </v-select>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <template v-for="item in items">
          <v-list-item v-if="item.public || (!item.public && !isPublic())" :key="item.title" :to="item.path" link color="primary">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="item.estilo" :class="item.estilo" >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else >
              <v-list-item-title>{{ item.title  }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="formularios.length">
          <v-list-group prepend-icon="mdi-clipboard-text" color="primary">
            <template v-slot:activator>
              <v-list-item-title>Formularios</v-list-item-title>
            </template>
            <template v-for="(child, i) in formularios">
              <v-list-item :key="i" :to="'/form/' + child.id" link class=".aotrolugar">
                <v-list-item-icon>
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.nombre" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <v-list-item :href="links.faq" target="_blank" link color="primary">
          <v-list-item-icon>
            <v-icon>mdi-help</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text small elevation="0" color="primary" class="text-normal" :loading="loading" @click.native="logout()">
            Salir
            <v-icon right dark>mdi-logout</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer app dark color="primary" right v-model="drawerRight" class="app-sidemenu" temporary width="370">
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title">
            <v-container>
              Notificaciones
            </v-container>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-tabs v-model="tab" background-color="indigo" centered dark fixed-tabs>
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab href="#tab-1">
          No leídos
        </v-tab>
        <v-tab href="#tab-2">
          Leidos
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 2" :key="i" :value="'tab-' + i">
          <v-list two-line v-if="i == 1 && no_leidos.length">
            <v-list-item-group v-model="selectedUnread" active-class="blue--text" multiple>
              <template v-for="(item, index) in no_leidos" >
                <v-list-item :key="index" @click="dialogs.notificationUnread = true">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.titulo"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.subtitulo"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.fecha"></v-list-item-action-text>
                      <v-icon v-if="!active" color="grey lighten-1">
                        mdi-check-all
                      </v-icon>
                      <v-icon v-else color="yellow darken-3">
                        mdi-check-all
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider color="black" v-if="index < no_leidos.length - 1" :key="'div-' + index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <v-list two-line v-if="i == 2 && leidos.length">
            <v-list-item-group v-model="selectedRead" active-class="blue--text" multiple>
              <template v-for="(item, index) in leidos">
                <v-list-item :key="index" @click="dialogs.notificationRead = true">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.titulo"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.subtitulo"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.fecha"></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider color="black" v-if="index < leidos.length - 1" :key="'div-' + index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs-items> 
    </v-navigation-drawer>
    <v-main class="background-root">
      <router-view :company="selectedCompany"></router-view>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
      <v-dialog v-model="dialogs.update" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea establecer el objetivo de la empresa?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancelCheck()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="setObjective()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="no_leidos.length && selectedUnread.length" v-model="dialogs.notificationUnread" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Notificación</v-card-title>
        <v-card-title>{{ no_leidos[selectedUnread].titulo }}</v-card-title>
        <v-card-text>{{ no_leidos[selectedUnread].fecha }}</v-card-text>
        <v-card-text>{{ no_leidos[selectedUnread].subtitulo }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancelNotification()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="updateNotifications()">
            Marcar como leída
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="leidos.length && selectedRead.length" v-model="dialogs.notificationRead" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Notificación</v-card-title>
        <v-card-title>{{ leidos[selectedRead].titulo }}</v-card-title>
        <v-card-text>{{ leidos[selectedRead].fecha }}</v-card-text>
        <v-card-text>{{ leidos[selectedRead].subtitulo }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancelNotification()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.helpHome" max-width="1000px">
      <v-carousel>
        <v-carousel-item v-for="(slide, idx) in sliders.home" :key="idx">
          <v-sheet color="secondary" height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <v-img :src="slide"/>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-dialog v-model="dialogs.helpHDC" max-width="1000px">
      <v-carousel>
        <v-carousel-item v-for="(slide, idx) in sliders.hdc" :key="idx">
          <v-sheet color="secondary" height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <v-img :src="slide"/>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
    </v-main>
    <TourGuide ref="homeGuide" v-on:abrirdrawer="abrirDrawer" v-on:huella-de-carbono="irAHuella"  :steps="tourSteps"/>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import TourGuide from "@/components/TourGuide.vue";
import {EventBus} from "@/EventBus";

export default {
  name: 'root',
  components: {TourGuide},
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    links: {
      faq: process.env.VUE_APP_LINK_FAQ,
      calendly: process.env.VUE_APP_LINK_CALENDLY
    },
    logo: require('@/assets/logo_home.png'),
    sliders: {
      home: [
        require('@/assets/sliders/home_01.png'),
        require('@/assets/sliders/home_02.png'),
        require('@/assets/sliders/home_03.png'),
        require('@/assets/sliders/home_04.png'),
        require('@/assets/sliders/home_05.png'),
        require('@/assets/sliders/home_06.png')
      ],
      hdc: [
        require('@/assets/sliders/hdc_01.png'),
        require('@/assets/sliders/hdc_02.png'),
        require('@/assets/sliders/hdc_03.png'),
        require('@/assets/sliders/hdc_04.png'),
        require('@/assets/sliders/hdc_05.png'),
        require('@/assets/sliders/hdc_06.png'),
        require('@/assets/sliders/hdc_07.png')
      ]
    },
    path: '/api/logout',
    username: '',
    firstName: '',
    rol: [],
    companies: [],
    selectedCompany: null,
    nameCompany: null,
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    selectedUnread: [],
    selectedRead: [],
    formularios: [],
    no_leidos: [],
    leidos: [],
    interval: null,
    tab: null,
    objective: null,
    dialogs: {
      update: false,
      notificationUnread: false,
      notificationRead: false,
      helpHome: false,
      helpHDC: false
    },
    objectives: [
      'Cero emisiones en el 2030',
      'Cero emisiones en el 2050',
      'Reducción anual del 4.2%'
    ],
    drawer: false,
    drawerRight: false,
    items: [
      { icon: 'mdi-home', title: 'Home', path: '/home', public: true ,estilo:null},
      { icon: 'mdi-account-circle', title: 'Perfil', path: '/profile', public: true ,estilo:null},
      { icon: 'mdi-chart-bar', title: 'Huella de Carbono', path: '/huella-de-carbono', public: false, estilo:'optionmenu' },
      { icon: 'mdi-chart-pie', title: 'Economía Circular', path: '/economia-circular', public: true ,estilo:null},
      { icon: 'mdi-shopping', title: 'Marketplace', path: '/marketplace', public: true ,estilo:null},
    ],
    tourSteps:[]
  }),
  mounted() {
    this.checkLocal()
    EventBus.$on('open-drawer', this.abrirDrawer);
    this.check()
    this.searchObjective()
    this.getNotifications()
    this.setIntervalOfNotifications()

  },
  watch: {
    selectedCompany: function (oldVal, newVal) {
      if (newVal && oldVal != newVal) {
        this.setCompany({ id: this.selectedCompany })
        this.getNameCompany(this.selectedCompany)
        this.searchObjective()
        this.getForms()
      }
    }
  },
  computed: {
    report() {
      return this.$store.state.general.report
    }
  },
  methods: {
    ...mapActions('general', [
      'setCompany',
      'setReport',
      'clearData',
    ]),
    tourGuide(tour) {
      if (!tour.one) {
        this.tourSteps = [
          {
            selector: '.filtrosPanel',
            title: 'Filtro',
            description: 'Escoge la fecha que deseas ver',
            position: 'bottom'
          },
          {
            selector: '.vermenu',
            title: 'Navegación',
            description: '<center>Visualiza el menú</center>',
            position: 'right',
            action: 'drawer'
          },
          {
            selector: '.aotrolugar',
            title: 'Elige una opción:',
            description: '<center>Haz click en "Huella <br> de carbono" para ver los detalles.</center>',
            position: 'bottom',
            action: 'huella-de-carbono'
          }
        ];
        this.$refs.homeGuide.start();
        console.log('Tour uno no lleno')
      }else if(tour.one && tour.two && !tour.three){
        console.log('Tour uno y dos llenos')
      }
    },
    checkLocal(){
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (!tour) {
        tour = { one: false, two: false, three: false };
        localStorage.setItem('tour', JSON.stringify(tour));
      }

      this.tourGuide(tour);
    },
    openObjectiveSelect() {
      this.$refs.objectiveSelect.focus();
    },
    abrirDrawer() {
      this.drawer = true;
    },
    irAHuella() {

      let tour = JSON.parse(localStorage.getItem('tour'));

      if (tour) {
        tour.one = true;
        tour.two = false;
        tour.three = false;
      } else {
        tour = { one: true, two: false, three: false };
      }

      localStorage.setItem('tour', JSON.stringify(tour));
      this.$router.push({ path: 'huella-de-carbono' });




    },
    check() {
      this.username = this.$session.get('user')
      this.firstName = this.$session.get('name')
      this.rol = this.$session.get('rol')
      this.companies = this.$session.get('companies')
      this.selectedCompany = (this.companies.length) ? this.companies[0].id : null
      this.setCompany({ id: this.selectedCompany })
      this.getNameCompany(this.selectedCompany)
      this.getForms()
    },
    getNameCompany(id) {
      for (let company of this.companies) {
        if (company.id == id) {
          this.nameCompany = ', ' + company.nombre
        }
      }
    },
    logout() {
      this.loading = true
      if (this.$session.exists()) {
        this.axios.post(this.host + this.path)
          .then(response => {
            this.clearData()
            this.$session.destroy()
            this.$router.push('/login')
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$router.push('/login')
      }
    },
    searchObjective() {
      for(let i = 0; i < this.companies.length; i++){
        if(this.selectedCompany === this.companies[i].id){
          this.objective = this.companies[i].objetivo ? this.companies[i].objetivo : null
        }
      }
    },
    objectiveCheck() {
      this.dialogs.update = true
    },
    cancelCheck() {
      this.dialogs.update = false
      this.objective = null
    },
    setObjective() {
      this.loading = true
      let data = {
        empresa: this.$store.state.general.company,
        objetivo: this.objective
      }
      this.axios.put(services.routes.objective, {data})
        .then(response => {
          this.dialogs.update = false
      })
      .catch(error => {
        this.error = true
        this.msgError = error.response.data.message || 'Servicio no disponible'
      })
      .finally(() => {
        this.loading = false
      })
    },
    profile() {
      if (this.$route.path !== '/profile') {
        this.$router.push('/profile')
      } 
    },
    isPublic() {
      if(this.rol.indexOf("publico") >= 0) {
        return true;
      }
      return false;
    },
    tour() {
      if (this.$route.path === '/home') {
        // this.dialogs.helpHome = true;
        let tour = JSON.parse(localStorage.getItem('tour'));
        tour = { one: false, two: false, three: false };
        localStorage.setItem('tour', JSON.stringify(tour));
        this.tourGuide(tour);


      }
      else if (this.$route.path === '/huella-de-carbono') {
        this.dialogs.helpHDC = true;
      }
    },
    getForms() {
      let params = {
        empresa: this.selectedCompany
      }
      this.axios.get(services.routes.form, { params })
        .then(response => {
          this.formularios = response.data.data
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {})
    },
    getNotifications() {
      this.axios.get(services.routes.notifications)
        .then(response => {
          this.no_leidos = response.data.data.no_leidos.length == 0 ? [] : response.data.data.no_leidos
          this.leidos = response.data.data.leidos.length == 0 ? [] : response.data.data.leidos
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {})
    },
    updateNotifications() {
      this.loading = true
      let data = {
        notificacion: this.no_leidos[this.selectedUnread].id
      }
      this.axios.put(services.routes.updateNotifications, { data })
        .then(response => {
          this.cancelNotification()
          this.getNotifications()
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancelNotification() {
      this.dialogs.notificationUnread = false
      this.selectedUnread = []
      this.dialogs.notificationRead = false
      this.selectedRead = []
    },
    setIntervalOfNotifications() {
      this.interval = setInterval(() => {
        this.getNotifications();
      }, 60000);
    },
    getReportGeneral() {
      this.setReport({ val: true })
    }
  }
}
</script>

<style scoped>
  .hotspot-element {
    position: relative;
  }
  .background-root {
    background-color: white !important;
  }
  .app-header {
    z-index: 99;
  }
  .app-sidemenu {
    z-index: 99;
  }
  .text-normal {
    text-transform: none !important;
  }
  .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  }
</style>
